import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useEffect, useRef, Suspense, PresentationControls} from 'react'
import { Preload, Loader } from '@react-three/drei'
import Experience from './Experience.jsx'

{/*import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js*/}

    const Camera = (props) => {
    const ref = useRef();
    const set = useThree((state) => state.set);
    useEffect(() => void set({ camera: ref.current }), []);
    useFrame(() => ref.current.updateMatrixWorld());
    return <perspectiveCamera ref={ref} {...props} />;
};

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <Canvas
   /* camera={ {
        fov: 60,
        near: 0.1,
        far: 110,
        position: [ -9.15, 8, 0 ],
        args: [3, 8, 12],
        zoom: 0.7
    } } */
    >

        <Camera position={[-9.15, 8, 0]} aspect={1.77} />
        {/*<PerspectiveCamera makeDefault position={[-6.15, 7, 0]} rotateY={1.570} aspect={1.77} fov={60} near={0.1} far={110}>
        </PerspectiveCamera>*/}
       {/*<Suspense fallback={ null /* <Placeholder position-y={ 0.5 } scale={ [2, 3, 2 ] } /}> */}
        {/*  <PresentationControls> */}
        <Experience />
         {/*  </PresentationControls> */}
    {/*</Suspense>*/}
    </Canvas>
)